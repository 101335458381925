<template>
	<div v-if="!showModalTemp && !showModalHall && !showModalAssociation">
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<a-row>
          <a-form-item class="ui-form__item" name="name" label="影院名称">
            <a-input v-model:value="formState.name" placeholder="请输入影院名称"></a-input>
          </a-form-item>

          <a-form-item class="ui-form__item" name="cinemaLinkId" label="影院编码">
            <a-input v-model:value="formState.cinemaLinkId" placeholder="请输入影院编码"></a-input>
          </a-form-item>

          <a-form-item class="ui-form__item" label="售票系统" name="dataSource">
            <a-select v-model:value="formState.dataSource" allow-clear placeholder="请选择售票系统" style="width: 200px;">
              <a-select-option :value="2">凤凰云智</a-select-option>
              <a-select-option :value="3">晨星云3.0</a-select-option>
              <a-select-option :value="4">辰星云4.0</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item class="ui-form__item" label="创建时间" name="createTime">
            <a-range-picker v-model:value="formState.createTime"/>
          </a-form-item>
				</a-row>

				<a-row>
					<a-col :span="18">
						<a-button v-permission="['cinema_basis_info_add']" type="primary" @click="onAdd">新增影院</a-button>
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div style="margin-top: 20px;">
				<a-table rowKey="id" :pagination="pagination" :columns="columns" :dataSource="list" :scroll="{ x: 2300 }">
					<template #bodyCell="{ column, record }">
            <template v-if="column.key === 'dataSource'">
              <div v-if="record.dataSource === 2">凤凰云智</div>
              <div v-if="record.dataSource === 3">晨星云3.0</div>
              <div v-if="record.dataSource === 4">晨星云4.0</div>
            </template>
						<template v-if="column.key === 'isDisabled'">
              <a-tag v-if="!record.isDisabled" color="#87d068">启用</a-tag>
              <a-tag v-if="record.isDisabled" color="#f50">禁用</a-tag>
						</template>
            <template v-if="column.key === 'createTime'">
              <div>
                {{ transDateTime(record.createTime) }}
              </div>
            </template>
						<template v-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']">
								<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
								</a-button>
								<template #overlay>
									<a-menu>
                    <div v-permission="['cinema_basis_info_edit']" @click="onEdit(record)">
                      <a-menu-item>编辑</a-menu-item>
                    </div>
                    <div v-permission="['cinema_basis_info_pay']" @click="getCinemaShareQR(record)">
                      <a-menu-item>影院二维码</a-menu-item>
                    </div>
										<div v-permission="['cinema_basis_info_sync_hall']" @click="onSyncHall(record)">
											<a-menu-item>
												同步影厅
											</a-menu-item>
										</div>
										<div v-permission="['cinema_basis_info_sync_schedules']" @click="onPullSchedules(record)">
											<a-menu-item>
												拉取排期
											</a-menu-item>
										</div>
                    <div v-permission="['cinema_basis_info_hallList']" @click="toHall(record)">
                      <a-menu-item>
                        影厅列表
                      </a-menu-item>
                    </div>
                    <div v-permission="['cinema_basis_info_director']" @click="toAssociation(record)">
                      <a-menu-item>
                        查看关联用户（负责人）
                      </a-menu-item>
                    </div>
                    <div v-permission="['cinema_basis_info_open']" v-if="record.isDisabled === 1" @click="onForbidden(record)">
                      <a-menu-item>
                        启用
                      </a-menu-item>
                    </div>
                    <div v-permission="['cinema_basis_info_close']" v-else @click="onForbidden(record)">
                      <a-menu-item>
                        禁用
                      </a-menu-item>
                    </div>
									</a-menu>
								</template>
							</a-dropdown>
						</template>
					</template>
				</a-table>
			</div>
      <a-modal v-model:visible="visible" :title="isPay ? '影城支付二维码' : '访问影城'" width="500px">
        <template #footer>
          <a-button type="primary" @click="visible = false;">关闭</a-button>
        </template>
        <div>影院名称：{{ title || '-' }}</div>
        <div style="text-align: center;">
          <a-image style="width: 300px;" :src="imgUrl"></a-image>
        </div>
      </a-modal>

      <a-modal v-model:visible="showModal" destroyOnClose title="新增影院" width="750px" @ok="onAddCinemaOk">
        <a-spin :spinning="loading">
          <a-form ref="addForm" scrollToFirstError :model="modelRef" name="addForm" :labelCol="{span: 6, xxl: 5}" :wrapperCol="{span: 16, xxl: 15 }">
            <a-form-item label="售票系统" name="dataSource" :rules="[{ required: true, message: '必填项不允许为空' }]">
              <a-select v-model:value="modelRef.dataSource" placeholder="请选择售票系统" style="width: 200px;">
                <a-select-option :value="2">凤凰云智</a-select-option>
                <a-select-option :value="3">晨星云3.0</a-select-option>
                <a-select-option :value="4">辰星云4.0</a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item label="影院编码" name="cinemaLinkId" :rules="[{ required: true, message: '必填项不允许为空' }]" extra="凤凰云智需使用影院内码，辰星使用国家编码">
              <a-input style="width: 200px;" v-model:value="modelRef.cinemaLinkId" placeholder="请输入影院编码"/>
            </a-form-item>

            <a-form-item label="影院负责人" name="people">
              <a-button type="primary" @click="showPeople = !showPeople">选择</a-button>
              <span v-if="selectedRowKeys.length !== 0" style="margin-left: 10px;">已选择{{ selectedRowKeys.length }}人</span>
            </a-form-item>

            <a-modal v-model:visible="showPeople" destroyOnClose title="新增负责人"  :maskClosable="false" width="750px" @ok="onSelectOk">
              <a-spin :spinning="loading">
                <a-form ref="peopleForm" :model="peopleFormState" name="peopleForm" @finish="onPeopleSearch">
                  <a-row>
                    <a-form-item class="ui-form__item" name="username" label="账号">
                      <a-input v-model:value="peopleFormState.username" placeholder="请输入账号"></a-input>
                    </a-form-item>

                    <a-form-item class="ui-form__item" name="nickname" label="昵称">
                      <a-input v-model:value="peopleFormState.nickname" placeholder="请输入昵称"></a-input>
                    </a-form-item>
                  </a-row>

                  <a-row>
                    <a-col :span="24" style="text-align: right;">
                      <a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
                      <a-button @click="peopleReset">重置</a-button>
                    </a-col>
                  </a-row>
                </a-form>
                <div style="margin-top: 20px;">
                  <a-table :pagination="peoplePagination" :columns="peopleColumns" :dataSource="peopleList" rowKey="id"
                           :rowSelection="{ type: rowSelectionType, selectedRowKeys: selectedRowKeys, onSelect: onSelectChange, onSelectAll: onSelectAll }"
                           :scroll="{ x: 450 }">
                    <template #bodyCell="{ column, record }">
                      <template v-if="column.key === 'action'">
                        <a-button type="primary" @click="onSelectOne(record)">选择</a-button>
                      </template>
                    </template>
                  </a-table>
                </div>
              </a-spin>
            </a-modal>
          </a-form>
        </a-spin>
      </a-modal>
		</a-spin>
	</div>
  <hall :id="id" v-if="showModalHall" @back="onBackHall"></hall>
  <association :id="id" v-if="showModalAssociation" @back="onBackAssociation"></association>
  <temp v-if="showModalTemp" :isEdit="isEdit" :id="id" @back="onBack"></temp>
</template>

<script>
  import temp from './temp.vue'
  import association from "./association.vue";
  import hall from './hall'
	import {
		Icon
	} from '@/components/icon/icon.js';
  import {
    getCinemaList,
    getCinemaSync,
    getCinemaHallSync,
    saveCinema,
    cinemaScheduleSync
  } from '@/service/modules/cinema.js';
  import {
    userList
  } from "@/service/modules/system.js";
  import {getCinemaShareQR, updateCinema} from "../../../../service/modules/cinema";
  export default {
		components: {
			Icon,
      hall,
      association,
      temp,
		},
		data() {
			return {
        showPeople: false,
				loading: false,
				isEdit: false,
				isSee: false,
        rowSelectionType: 'checkbox',
        selectedRowKeys: [],
				id: 0,
        modelRef: {

        },
				formState: {
					// organizationId: 0,
					id: 0,
				},
        peopleFormState: {

        },
				searchData: {},
        peopleSearchData: {},
				showModal: false,
        showModalTemp: false,
        showModalHall: false,
        showModalAssociation: false,
        peopleColumns: [{
          title: '账号',
          dataIndex: 'username',
        }, {
          title: '昵称',
          dataIndex: 'nickname',
        }, {
          title: '操作',
          key: 'action',
        }],
        peopleList: [],
				list: [],
				columns: [{
          title: '售票系统',
          key: 'dataSource'
        }, {
          title: '影院国家编码',
          dataIndex: 'code'
        }, {
          title: '影院内码',
          dataIndex: 'cinemaLinkId'
        }, {
					title: '影院名称',
					dataIndex: 'name',
				}, {
					title: '影院地址',
					dataIndex: 'address',
				}, {
          title: '影厅数量',
          dataIndex: 'hallCount',
        }, {
					title: '创建时间',
					key: 'createTime',
				}, {
          title: '状态',
          key: 'isDisabled',
        }, {
          title: '创建账户',
          dataIndex: 'createUserName',
        }, {
					title: '操作',
					key: 'action',
					width: 150,
					fixed: 'right'
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
        peoplePagination: {
          showSizeChanger: true,
          showQuickJumper: true,
          size: "middle",
          showTotal: (total) => {
            return "共 " + total + " 条";
          },
          total: 0,
          current: 1,
          pageSize: 10,
          onChange: (page) => {
            this.peoplePagination.current = page;
            this.getData();
          },
          onShowSizeChange: (page, pageSize) => {
            this.peoplePagination.current = 1;
            this.peoplePagination.pageSize = pageSize;
            this.getData();
          }
        },
				visible: false,
				isPay: false,
				title: '',
				imgUrl: '',
			}
		},
		created() {
      this.getData();
      this.getUserList();
		},
		methods: {
      onBack(isRef) {
        this.showModalTemp = false;
        if (isRef) {
          this.$nextTick(() => {
            this.getData();
          })
        }
      },
      onBackHall(isRef) {
        this.showModalHall = false;
        if (isRef) {
          this.$nextTick(() => {
            this.getData();
          })
        }
      },
      onBackAssociation(isRef) {
        this.showModalAssociation = false;
        if (isRef) {
          this.$nextTick(() => {
            this.getData();
          })
        }
      },
			onSearch() {
				this.pagination.current = 1;
				// //this.pagination.pageSize = 10;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.searchData.organizationId = this.searchData.organizationId ? this.searchData.organizationId : undefined;
				this.searchData.id = this.searchData.id ? this.searchData.id : undefined;
				this.getData();
			},
      onPeopleSearch() {
        this.peoplePagination.current = 1;
        this.peopleSearchData = JSON.parse(JSON.stringify(this.peopleFormState));
        this.getUserList();
      },
			reset() {
        this.$refs.form.resetFields();
				this.onSearch();
			},
      peopleReset() {
        this.$refs.peopleForm.resetFields();
        this.onPeopleSearch();
      },
			async getData() {
        if(this.searchData.createTime && this.searchData.createTime.length === 2) {
          this.searchData.createStartTime = this.moment(this.formState.createTime[0].startOf('day')).unix();
          this.searchData.createEndTime = this.moment(this.formState.createTime[1].endOf('day')).unix();
        }
        delete this.searchData.createTime;
				this.loading = true;
				try {
					let ret = await getCinemaList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						...this.searchData
					})
					this.loading = false;
					if (ret.code === 200) {
						this.list = ret.data.list;

						this.pagination.total = ret.data.totalCount;
					}
				} catch(e) {
					this.loading = false;
				}
			},
      async getUserList() {
        this.loading = true;
        try {
          let ret = await userList({
            page: this.peoplePagination.current,
            pageSize: this.peoplePagination.pageSize,
            ...this.peopleSearchData
          })
          this.loading = false;
          if(ret.code === 200) {
            this.peopleList = ret.data.list;
            this.peoplePagination.total = ret.data.totalCount;
          }
        } catch(e) {
          this.loading = false;
        }
      },
      async onAddCinemaOk() {
        this.$refs.addForm.validateFields().then(async () => {
          this.loading = true;
          try {
            let ret = await saveCinema({
              cinemaLinkId: this.modelRef.cinemaLinkId,
              dataSource: this.modelRef.dataSource,
              adminUserIdList: this.selectedRowKeys
            })
            this.loading = false;
            if (ret.code === 200) {
              this.$message.success('操作成功');
              this.modelRef = {
                dataSource: 0,
                code: '',
              }
              this.selectedRowKeys = [];
              this.showModal = false;
              this.getData();
            }
          } catch(e) {
            this.loading = false
          }
        })
      },
			async onSync() {
				this.loading = true;
				try {
					let ret = await getCinemaSync({});
					this.loading = false;
					if (ret.code === 200) {
						this.$message.success('更新成功');
						this.getData();
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async onSyncHall(item) {
				this.loading = true;
				try {
					let ret = await getCinemaHallSync({
						id: item.id
					});
					this.loading = false;
					if (ret.code === 200) {
						this.$message.success('更新成功');
						this.getData();
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async onPullSchedules(item) {
				this.loading = true;
				try {
					let ret = await cinemaScheduleSync({
						id: item.id,
					});
					this.loading = false;
					if (ret.code === 200) {
						this.$message.success('拉取排期成功');
					}
				} catch(e) {
					this.loading = false;
				}
			},
			onAdd() {
        this.modelRef = {};
				this.isEdit = false;
				this.id = 0;
				this.showModal = true;
			},
      onEdit(item) {
        this.showModalTemp = true;
        this.id = item.id;
        this.isEdit = true;
      },
      toHall(item) {
        this.showModalHall = true;
        this.id = item.id;
      },
      toAssociation(item) {
        this.showModalAssociation = true;
        this.id = item.id
      },
			async getCinemaShareQR(item) {
				this.loading = true;
				try {
					let ret = await getCinemaShareQR({
						id: item.id,
					});
					this.loading = false;
					if (ret.code === 200) {
						this.title = item.name;
						this.imgUrl = ret.data.shareQR;
						this.isPay = true;
						this.visible = true;
					}
				} catch(e) {
					this.loading = false;
				}
			},
      onSelectOne(item) {
        if(this.selectedRowKeys.indexOf(item.id) === -1) {
          this.selectedRowKeys.push(item.id);
          this.showPeople = false;
        };
      },
      onSelectOk() {
        this.showPeople = false;
      },
      onSelectChange(record, selected) {
        if (this.rowSelectionType === 'checkbox') {
          if (selected) {
            // this.selectedRowData.push(JSON.parse(JSON.stringify(record)));
            this.selectedRowKeys.push(record.id);
          } else {
            this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(record.id), 1);
            // this.selectedRowData = this.selectedRowData.filter(item => {
            // 	return item.id !== record.id;
            // });
          }
        }
      },
      onSelectAll(selected) {
        if (selected) {
          this.peopleList.forEach(item => {
            if (this.selectedRowKeys.indexOf(item.id) === -1) {
              // this.selectedRowData.push(JSON.parse(JSON.stringify(item)));
              this.selectedRowKeys.push(item.id);
            }
          })
        } else {
          this.peopleList.forEach(item => {
            if (this.selectedRowKeys.indexOf(item.id) !== -1) {
              // this.selectedRowData.splice(this.selectedRowKeys.indexOf(item.id), 1);
              this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(item.id), 1);
            }
          })
        }
      },
      onForbidden(item) {
        let postData = JSON.parse(JSON.stringify(item));
        postData.isDisabled = postData.isDisabled ? 0 : 1;
        this.$confirm({
          title: '提示',
          content: '确定' + (item.isDisabled ? '启用' : '禁用') + '吗？',
          onOk: async () => {
            this.loading = true;
            try {
              let ret = await updateCinema(postData);
              this.loading = false;
              if (ret.code === 200) {
                this.$message.success(item.isDisabled ? '启用' : '禁用' + '成功');
                this.getData();
              }
            } catch(e) {
              this.loading = false;
            }
          }
        })
      },
		}
	}
</script>

<style>
	.ui-form__item {
		margin-right: 30px;
	}
</style>
